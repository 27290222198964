<template>
    <v-container fluid>
        <v-row row wrap>
            <template v-if="loading">
                <v-col cols="12" class="text-center" mt-4>
                <v-progress-circular
                    :width="3"
                    color="blue"
                    indeterminate
                ></v-progress-circular>
                </v-col>
            </template>
            <template v-else>
                <template v-if="itemNotOrdered">
                    <v-col cols="12" class="text-xs-left ma-2">
                        <h1>{{title}}</h1>
                        <p>Read about membership benefits 
                            <a href="http://www.wrenhousebrewing.com/saguaro-society" target="_blank">here.</a>
                        </p>
                        <h3>{{windowTitle}}</h3>
                        <p>{{window}} </p>
                        <h3>Select Membership Options</h3>

                        <v-list>
                            <v-list-item v-for="(option, index) in activeMembershipProduct.options" v-bind:key="option.name">
                                <v-select @change="updateCart"
                                    v-model="optionSelections[index]"
                                    :items="option.choices"
                                    :label="option.name"
                                    :required="option.required"
                                    dense
                                    outlined
                                ></v-select>
                            </v-list-item>
                        </v-list>

                        <template v-if="isKeystoneInvitee">
                            <h3>
                                <v-icon class="mb-1" color="yellow darken-3"  >mdi-diamond-stone</v-icon>
                                KEYSTONE TIER INVITATION 
                                <v-icon class="mb-1" color="yellow darken-3"  >mdi-diamond-stone</v-icon>
                            </h3>
                             
                            <p>Cheers to your exceptional engagement with the Saguaro Society! You are invited to join the Keystone Tier of the 
                                Saguaro Society.
                                <a href="https://www.wrenhousebrewing.com/saguaro-society" target="_blank">Learn more about additional benefits exclusive to Keystone members here.</a>
                            </p>
                            <v-checkbox
                                v-model="joinKeystone"
                                label="Add the Keystone Tier to my membership for $50."
                                @click="buildOrder()"
                            ></v-checkbox>
                        </template>


                        <v-checkbox
                        v-model="termsAccepted">
                            <div slot="label" @click.stop="">
                                Do you accept the
                                <a href="javascript:;" @click.stop="terms = true">terms and conditions</a>?
                            </div>
                        </v-checkbox>
                    
                        <template v-if="!readyForPayment && termsAccepted">
                            <v-alert type="info" outlined>
                            Select membership options above
                            </v-alert>
                        </template>

                        <template v-else>
                            <v-col center cols="12">
                                <SquareCart
                                    v-show="readyForPayment"
                                    v-bind:cart="cart"
                                    v-bind:showTitle="showTitle"
                                    v-bind:showDetails="showDetails"
                                />
                            </v-col>
                        </template>

                        <template v-show="!cartLoading">
                            <v-col class="pa-0 ma-0" cols="12">
                                <form action="processRenewal">
                                    <Square 
                                        v-show="readyForPayment" 
                                        v-bind:buttonText="purchaseButtonText"
                                        v-bind:showPaymentForm=readyForPayment 
                                        @payment-successful="processRenewal"/>
                                </form>
                            </v-col>
                        </template>

                        <v-alert type="error" dismissible v-model="alert">
                            {{ error }}
                        </v-alert>

                    </v-col>
                </template>

                <template v-else>
                    <v-col center ma-3>
                        <v-card flat>
                            <v-card-title> 
                                <v-icon class="mr-2" color="green">mdi-check-circle</v-icon>
                                <div class="headline">You are all set!</div>
                            </v-card-title>
                            <v-card-subtitle class="headline mt-2"> Thank you for purchasing a Saguaro Society {{isKeystoneMember ? "Keystone":""}} Membership for {{year}} </v-card-subtitle>
                            <v-card-text>
                            <div class="font-weight-thin"><br>You can find your membership order receipt in your <router-link to="orders">Order History</router-link>.  
                            The barcode on that receipt is your ticket to pickup your member swag during the new year.<br><br> Cheers! <br> -WHBC
                             </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </template>
   

            <v-dialog v-model="terms" width="70%">
                <v-card>
                    <v-card-title class="title">Terms and Conditions</v-card-title>
                    <v-card-text>
                        <ul>
                            <li v-for="line in termsContent" v-bind:key=line>{{ line }}</li>
                        </ul>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="purple"
                        @click="terms = false; termsAccepted = true"
                    >I agree</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import '@/settings'
import Square from '@/components/payment/SquareCheckout'
import SquareCart from '@/components/payment/SquareCart'
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'
import api from '@/store/api'
import moment from 'moment-timezone'
import AllMixins from '@/mixins/AllMixins'
export default {
    data () {
        return {
            inviteeWindow: "You have 72 hours from the time your invitation email was sent to complete purchase of a membership. If you do not purchase within this time, your slot may be sold to another invitee from the waiting list.",
            emailConfirm: '',
            password: '', //passed into firebase auth along with user info
            passwordConfirm: '', 
            remaining: 17,  //TODO update to show actual number of memberships remaining
            alert: false,
            payment: false,
            checkout: false,
            cartLoading: false,
            order: null,
            showTitle: false,
            showDetails: false,
            optionSelections: [],
            purchaseButtonText: "Purchase Membership",
            terms: false,
            termsAccepted: false,
            joinKeystone: false,
        }
    },
    mixins: [AllMixins],
    created: function () {  //create the product order and when returns, store in state
        let self = this
        api.loadUserOrders()
        .then( () => {
            //this.getMembershipProducts()
            api.getAvailableMemberships()
            .then( () => {
                if(self.activeMembershipProduct == null)
                    console.log("null membership item")
                else
                    self.buildOrder()
            })
        })
    },
    watch:{
        joinKeystone (val) {
            this.buildOrder() 
        },
        optionSelections: {
            handler: function (val) {
                this.updateCart()
            },
            deep: true
        }
    },
    components:   {
        Square,
        SquareCart
    },
    computed: {
        ...mapGetters ([
            'loading',
            'cart', 
            'isKeystoneMember',
            'isKeystoneInvitee',
        ]),
        renewalWindow() { return "During this period, memberships are only available for purchase by existing members. " + 
            "If you have not renewed your membership by the end of this period, your membership slot may be sold to the public."},
        renewalPeriod() {
            if (this.activeMembershipProduct == null) return "" 
            else return "(" + this.shortDate(this.activeMembershipProduct.orderStart) + " - " + this.shortDate(this.activeMembershipProduct.orderEnd) + ")"
        },
        year () { return "" + (new Date().getFullYear() + 1)},
        title() { return (this.invitee ? "Purchase": "Renew") + " Membership for " + this.getMembershipProductYear()},
        window() { return this.invitee ? this.inviteeWindow: this.renewalWindow},
        windowTitle() { return this.invitee ? "Invitation Active Period": "Member Renewal Period " + this.renewalPeriod},
        termsContent () {
            if(this.activeMembershipProduct == null) return []
            else return this.activeMembershipProduct.terms
        },
        readyForPayment () {  //payment form is only showed if everything else is entered, valid. submitting payment form submits all
            if (this.termsAccepted && this.mappedSelections)// && this.$refs.form.validate())
                return true
            return false
        },
       // cart () { return this.$store.state.cart },
        error () { return this.$store.state.error },
        loading () { return this.$store.state.loading },
        invitee () {return this.$store.state.userInfo.userStatus == "invitee"},
        activeMembershipProduct () { return this.$store.state.membershipProduct}, //place membership product into similar structure as an item in Product.vue
        itemNotOrdered () {
            if(this.activeMembershipProduct == null)
                return true
            return this.renewalOrder == null
        },
        renewalOrder () {
            let orders = this.$store.state.userOrders.filter(order => 
                order.status != "Refunded" && order.productId == this.activeMembershipProduct.productId
            )
            if(orders.length > 1){
                console.log("Warning: multiple membership purchases")
            }
            if(orders.length > 0)
                return orders[0]
            else return null
        },
        mappedSelections () { //return false if a required field is not selected
            let map = []
            let self = this
            let completed = true
            this.activeMembershipProduct.options.forEach( (option, index) => {
                if(option.required && self.optionSelections[index] == undefined)
                    completed = false
                map.push({ 
                    name: option.name,
                    value: self.optionSelections[index],
                })
            })
            if(completed){
                return map
            }
            else return false
        },
    },
    methods: {
        ...mapMutations([
            'setCart'
        ]),

        buildOrder () { //build essential items to view cart; this will be remade on server to create and process the order

            if(this.activeMembershipProduct != null){
                console.log(this.activeMembershipProduct)
                
                this.cartLoading = true

                let lineItem = {}
                lineItem.name = this.activeMembershipProduct.name //Society Membership 2023
                lineItem.quantity = "1"

                let variation = this.activeMembershipProduct.variations[0]
                if(this.joinKeystone){
                    variation = this.activeMembershipProduct.variations[1]
                }
                lineItem.base_price_money = { amount: variation.price * 100, currency: 'USD'}
                lineItem.gross_sales_money = { amount: lineItem.base_price_money.amount * lineItem.quantity, currency: 'USD'}
                lineItem.variation_name = variation.name        

                var taxes = {}
                taxes.name = 'Phoenix AZ'
                taxes.percentage = '8.6'
                lineItem.taxes = [taxes]

                let order = {}
                order.name = this.activeMembershipProduct.name
                order.options = this.mappedSelections
                order.product = this.activeMembershipProduct
                order.product.selected = "1"
                order.product.squareId = variation.squareId //this assignment from variation to product tells server which square catalog item to use
                order.product.variation = variation //this is needed to be able to tell which membership variation was purchased

                order.line_items = [lineItem]
                order.total_tax_money = { amount: 0, currency: 'USD'}
                order.total_money = { amount: 0, currency: 'USD'}
                order.line_items.forEach( item => {
                    order.total_tax_money.amount += item.gross_sales_money.amount * 0.086
                    order.total_money.amount += (item.gross_sales_money.amount + order.total_tax_money.amount)
                })
            


                this.setCart(order)
                this.cartLoading = false
            }
        },
        updateCart () {
            let order = Object.assign({},this.cart)
            order.options = this.mappedSelections
            this.setCart(order)
        },
        processRenewal () { //this is now done on server automatically when purchased. this function just updates local data
            api.loadUserOrders() //refresh user orders to include the just-processed renewal order
            if(this.invitee){ //not a renewal
                let uid = this.$store.state.user.uid
                let self = this
                const functionServer = ENV.functionsServer()
                return new Promise( function(resolve) {
                    axios.post(functionServer + "assignnewmembernumber", {uid: uid})
                    .then( function(response) {
                        console.log(response)
                        api.loadUserInfo() //refresh the user info after membership update
                    })
                })
            }
          //  else{
          //      api.loadUserInfo() //refresh the user info after membership update
          //  }            
        },
        shortDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").format("M/D/YYYY")
        },
    },
    watch: {
        error (value) {
            if (value) {
                this.alert = true
            }
        },
        alert (value) {
            if (!value) {
                this.$store.commit('setError', null)
            }
        },
    }
}
</script>
